import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { proceed } from "app/features/inbound";
import { AppDispatch } from "app/store";
import useSocket from "utilities/hooks/use-socket";
import styles from "./index.module.scss";
import { _HOST } from "config/constant/endpoints";
import Countdown from "react-countdown";
import CountdownElement from "./countdown";
import { endAndHome } from "components/molecules/navbar";
// Random component
const Completionist = () => <span>00:00</span>;

const renderer = ({
  hours,
  minutes,
  seconds,
  completed,
}: {
  hours: any;
  minutes: any;
  seconds: any;
  completed: boolean;
}) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span>
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </span>
    );
  }
};

const OTP = () => {
  const dispatch: AppDispatch = useDispatch();
  const { onConnect } = useSocket();
  const session = localStorage.getItem("pickup")!.split(",");
  const [otp, setOTP] = useState<string>("");
  useEffect(() => {
    const socket = io(_HOST);
    socket.on("connect", onConnect);
    socket.emit("call", "join", { room: session[1] });
    socket.on("conferencing", (...arg: any) => {
      console.log(arg);
      const isProceed = arg[arg.length - 1];
      setOTP(arg[1]);
    });

    return () => {
      socket.off("connect");
      socket.off("conferencing");
      socket.close();
    };
  }, []);

  return (
    <div className={styles.otp}>
      {/* <div> { createElement(FaCheck) } </div> */}
      <h4>Masukan One Time Password untuk verifikasi data anda</h4>
      <p>Kode OTP telah di kirim ke +62812 3456 7xxx</p>
      <div className={styles.inputOTP}>
        <input type="password" disabled value={otp[0] && "*"} />
        <input type="password" disabled value={otp[1] && "*"} />
        <input type="password" disabled value={otp[2] && "*"} />
        <input type="password" disabled value={otp[3] && "*"} />
        <input type="password" disabled value={otp[4] && "*"} />
        <input type="password" disabled value={otp[5] && "*"} />
      </div>
      <button>Kirim Ulang Kode OTP</button>
      <div className={styles.timer}>
        {/* <Countdown date={Date.now() + 120000} renderer={renderer} /> */}
        <CountdownElement />
      </div>
    </div>
  );
};

export default OTP;
