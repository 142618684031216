import styles from "./index.module.scss"

type TabType = {
  label: string
  isActive: boolean
}

const Tabbar = (props: { tabs: Array<TabType> }) => {
  return (
    <div className={styles.tab}>
      { props.tabs.map((tab, id) => (
        <button key={ id } className={ tab.isActive ? styles.active : undefined }>
          { tab.label }
        </button>
      )) }
    </div>
  )
}

export default Tabbar