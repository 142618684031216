import styles from "./index.module.scss";
import { createElement, useCallback, useEffect, useState } from "react";
import npwp from "assets/images/npwp.jpg";
import StatusUpload from "components/molecules/status-bar/upload";
import { FaPlusCircle } from "react-icons/fa";
import useSocket from "utilities/hooks/use-socket";
import { io } from "socket.io-client";
import { useDispatch } from "react-redux";
import { proceed } from "app/features/inbound";
import { DOCS_OPEN_ACCOUNT } from "config/initial-state";
import { AppDispatch } from "app/store";
import { _HOST } from "config/constant/endpoints";
import { endAndHome } from "components/molecules/navbar";

const NPWP = () => {
  const [stringImg, setImage] = useState<string>("");
  const dispatch: AppDispatch = useDispatch();
  const { onConnect } = useSocket();
  const session = localStorage.getItem("pickup")!.split(",");

  const onConference = useCallback(
    (...arg: any) => {
      console.log(arg);
      const isProceed = arg[arg.length - 1];
      if (arg[0] === 6 && arg[1] !== "") {
        setImage(arg[1]);
        localStorage.setItem("npwp", arg[1]);
      }
      if (arg[0] === 6 && arg[1] === "") {
        setImage("");
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const socket = io(_HOST);
    socket.on("connect", onConnect);
    socket.emit("call", "join", { room: session[1] });
    socket.on("conferencing", onConference);

    return () => {
      socket.off("connect");
      socket.off("conferencing");
      socket.close();
    };
  }, []);

  return (
    <div className={styles.npwp}>
      <StatusUpload status={DOCS_OPEN_ACCOUNT} />
      {stringImg !== "" ? (
        <>
          <div>
            <img src={`data:image/png;base64,${stringImg}`} alt="npwp" />
          </div>
        </>
      ) : (
        <div>
          <div>{createElement(FaPlusCircle)}</div>
        </div>
      )}
    </div>
  );
};

export default NPWP;
