import { configureStore } from "@reduxjs/toolkit";
import counterReducer from 'app/features/counter'
import inboundReducer from "app/features/inbound";
import timerReducer from 'app/features/timer'
import chatReducer from 'app/features/meeting-chat'
import queueReducer from 'app/features/queue'

const store = configureStore({
  reducer: {
    counter: counterReducer,
    inbound: inboundReducer,
    timer: timerReducer,
    chatOnMeeting: chatReducer,
    queueList: queueReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
