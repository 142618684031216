const ENDPOINTS = {
  https: process.env.REACT_APP_HOST,
  http: process.env.REACT_APP_LOCALHOST,
} 

const ENDPOINT = ENDPOINTS.https

// endpoints without params
export const _HOST = `${ENDPOINTS.https}`
export const _LOCALHOST = `${ENDPOINTS.http}`
export const _LOGIN_API = `${ENDPOINT}/api/agent/login`
export const _GENERATE_SIGN_API = `${ENDPOINT}/api/zoom/signature`
export const _QUEUE_API = `${ENDPOINT}/api/queue`
export const _TOGGLE_READY_API = `${ENDPOINT}/api/agent/toggleReady`
export const _PICKUP_API = `${ENDPOINT}/api/agent/pickup`

// endpoints with params
export const _QUEUE_OPTION_API = (option: string) => `${ENDPOINT}/api/queue/queue-list/${option}`