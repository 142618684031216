import { _HOST } from "config/constant/endpoints";
import { createElement, useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { proceed } from "app/features/inbound";
import { AppDispatch } from "app/store";
import useSocket from "utilities/hooks/use-socket";
import styles from "./index.module.scss";
import { endAndHome } from "components/molecules/navbar";

const SuccesRegister = () => {
  const dispatch: AppDispatch = useDispatch();
  const { onConnect } = useSocket();
  const session = localStorage.getItem("pickup")!.split(",");

  return (
    <div className={styles.successReg}>
      <div> {createElement(FaCheck)} </div>
      <h1>Registrasi Berhasil</h1>
      <h4>Harap aktivasi akun Anda</h4>
    </div>
  );
};

export default SuccesRegister;
