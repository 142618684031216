import {
  createElement,
  FunctionComponentElement,
  memo,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useContext,
  useState,
} from "react";
import { IconBaseProps } from "react-icons";
import {
  FaAngleDown,
  FaAngleLeft,
  FaAngleRight,
  FaSignOutAlt,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import ZoomContext from "../../../context/zoom-context";
import { ROUTES } from "../../../routes";
import { authLogout } from "../../../utilities/services/auth/services";
import styles from "./index.module.scss";
import { increment, decrement } from "../../../app/features/counter";
import { selectTimer, stopRecord } from "app/features/timer";
import { setOnline } from "../../../utilities/services/auth/services";
import { ZoomClient } from "context/types/zoom";

type NavType = {
  element: FunctionComponentElement<{}>;
  path?: string;
  index: boolean;
  children?: Array<any> | null;
  icon?: FunctionComponentElement<IconBaseProps>;
  hide?: boolean;
  accordion?: boolean;
};

type AccordionType = {
  isOpen: boolean;
  id: number | null;
};

export const endAndHome = (zoomClient: ZoomClient) => {
  (async () => await zoomClient.leave(true))();
  setOnline();
  console.log(window.location.href, "url");
  window.location.href =
    window.location.protocol === "http" || window.location.protocol === "https"
      ? window.location.protocol + "://" + window.location.hostname
      : window.location.href.match("^.+?[^/:](?=[?/]|$)")![0];
};

const NavBar = () => {
  const navigate = useNavigate();
  const zoomClient = useContext(ZoomContext);
  const [accordion, setAccordion] = useState<AccordionType | null>(null);
  const [navShrink, setNavShrink] = useState(false);
  const timer = useSelector(selectTimer);
  const dispatch = useDispatch();
  const handleNav = useCallback(() => {
    if (!navShrink) {
      setAccordion({
        id: null,
        isOpen: false,
      });
    }
    setNavShrink(!navShrink);
  }, [navShrink]);

  const navigateToHome =
    window.location.href.toString().search("conversation") !== -1
      ? (e: SyntheticEvent) => {}
      : (e: SyntheticEvent) => {
          (async () => await zoomClient.leave(true))();
          setOnline();
          // window.location.replace("/");
          dispatch(stopRecord());
          navigate("/");
        };

  const handleAccordion = (id: number) => {
    if (navShrink) {
      setAccordion({
        isOpen: false,
        id: null,
      });
    } else if (!accordion) {
      setAccordion({
        isOpen: true,
        id: id,
      });
    } else {
      setAccordion({
        isOpen: !accordion.isOpen,
        id: id,
      });
    }
  };

  //console.log(timer.all);

  const NavButton = (nav: NavType, id: number) => {
    if (nav.path !== "log") {
      return (
        <li key={id}>
          <button
            onClick={nav.index ? navigateToHome : () => handleAccordion(id)}
            className={styles.home}
          >
            <div>{nav.icon as ReactNode}</div>
            <span>
              {!nav.path
                ? "Home"
                : nav.path[0].toUpperCase() + nav.path.substring(1)}
            </span>
            {nav.children && createElement(FaAngleDown)}
          </button>
          {accordion?.id === id && accordion.isOpen && (
            <ul className={styles.submenu}>
              {nav.children?.map((item, id) => (
                <li key={id}>
                  {/* { createElement(FaCircle) } */}
                  <button>
                    <span>
                      {item.path[0].toUpperCase() + item.path.substring(1)}
                    </span>
                  </button>
                </li>
              ))}
            </ul>
          )}
          {nav.index && (
            <button onClick={handleNav} className={styles.hide}>
              {createElement(navShrink ? FaAngleRight : FaAngleLeft)}
            </button>
          )}
        </li>
      );
    } else {
      return (
        <li key={id}>
          <NavLink to={nav.path as string}>
            <div>{nav.icon as ReactNode}</div>
            <span>{nav.path}</span>
          </NavLink>
        </li>
      );
    }
  };

  return (
    <div className={navShrink ? styles.hide : styles.show}>
      <nav>
        <ul className={styles.ul}>
          {ROUTES[0].children.map(
            (nav, id) => !nav.hide && NavButton(nav as NavType, id)
          )}
          <li className={styles.lastli}>
            <button onClick={authLogout}>
              <div>{createElement(FaSignOutAlt)}</div>
              <span>Log Out</span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default memo(NavBar);
