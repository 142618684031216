import { memo } from "react";
import Countdown from "react-countdown";
const Completionist = () => <span>00:00</span>;

const renderer = ({
  hours,
  minutes,
  seconds,
  completed,
}: {
  hours: any;
  minutes: any;
  seconds: any;
  completed: boolean;
}) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span>
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </span>
    );
  }
};

const CountdownElement = () => {
  return <Countdown date={Date.now() + 120000} renderer={renderer} />;
};

export default memo(CountdownElement);
