import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { proceed } from "app/features/inbound";
import { AppDispatch } from "app/store";
import useSocket from "utilities/hooks/use-socket";
import styles from "./index.module.scss";
import { _HOST } from "config/constant/endpoints";
import moment from "moment";
import "moment/locale/id";
import { endAndHome } from "components/molecules/navbar";

const ResiRTGS = () => {
  const dispatch: AppDispatch = useDispatch();
  const { onConnect } = useSocket();
  const session = localStorage.getItem("pickup")!.split(",");
  const pickup = localStorage.getItem("pickup")!.split(",");
  const [jam, setJam] = useState(moment().format("HH:mm"));
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("rtgs-form-1")!)
  );
  const [allPage, setAllPage] = useState(1);
  // const [otp, setOTP] = useState<string>("");

  useEffect(() => {
    moment.locale("id");

    const socket = io(_HOST);
    socket.on("connect", onConnect);
    socket.emit("call", "join", { room: session[1] });
    socket.on("conferencing", (...arg: any) => {
      console.log(arg);
      const isProceed = arg[arg.length - 1];
      // setOTP(arg[1]);
      if (arg[0] === 20 && arg[1]) {
        setData(JSON.parse(localStorage.getItem(`rtgs-form-${arg[1]}`)!));
        setAllPage(arg[2]);
      }
      if (arg[0] === 20 && isProceed) {
        dispatch(proceed(11));
      }
      if (arg[0] === 99) {
        endAndHome(JSON.parse(localStorage.getItem("zoom-client")!));
      }
    });

    return () => {
      socket.off("connect");
      socket.off("conferencing");
      socket.close();
    };
  }, []);
  return (
    <>
      <h2 style={{ textAlign: "center" }}>Resi</h2>
      <h4 style={{ color: "#56b740", textAlign: "center" }}>
        Transaksi Anda Berhasil
      </h4>
      <div className={styles.table} style={{ flexFlow: "unset" }}>
        <div>
          <p>No. Formulir</p>
          <p>No. Referensi</p>
          <p>Tanggal</p>
          <p>Jam</p>
          <p>Rekening Sumber Dana</p>
          <p>Nama Pemilik Rekening</p>
          <p>Jenis Layanan</p>
          <p>Bank Penerima</p>
          <p>Nama Penerima</p>
          <p>Penerima Manfaat</p>
          <p>Jenis Penduduk</p>
          <p>Biaya</p>
          <p>Nominal Transaksi</p>
          <p>Berita</p>
        </div>
        {data && (
          <div>
            <p>2103212</p>
            <p>XX12302120</p>
            <p>{moment().format("LL")} </p>
            <p>{jam} WIB</p>
            <p>{data[3].split("-")[0]}</p>
            <p>{data[3].split("-")[1]}</p>
            <p>{data[9]}</p>
            <p>{data[5]}</p>
            <p>{data[7]}</p>
            <p>{data[10]}</p>
            <p>{data[11]}</p>
            <p>Rp 2,500</p>
            <p>Rp {data[8]}</p>
            <p>{data[12] !== '' || data[12] !== ' ' ? data[12] : " - "}</p>
          </div>
        )}
      </div>
      <h5>
        halaman {data[3] ? data[13] : 1} dari{" "}
        {data[14] ? (data[14] === 5 ? allPage : data[14]) : 1}
      </h5>
    </>
  );
};

export default ResiRTGS;
