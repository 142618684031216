import { createElement } from 'react'
import { FaAddressCard, FaMoneyCheckAlt, FaUserAltSlash } from 'react-icons/fa'
import styles from './index.module.scss'

const KTPCheck = [
  { label: 'Validasi Bank', icon: createElement(FaMoneyCheckAlt), data: ['NIK'] },
  { label: 'Validasi Dukcapil', icon: createElement(FaAddressCard), data: ['NIK', 'Nama Lengkap', 'Tempat Lahir', 'Tanggal Lahir'] },
  { label: 'Validasi DTOTT', icon: createElement(FaUserAltSlash), data: ['Nama Lengkap'] },
]

const KTPChecking = () => {

  return (
    <div className={styles.statusUpload}>
      {
        KTPCheck && KTPCheck.map(item => (
          <div key={item.label}>
            <div>{item.icon} <p>{item.label}</p></div>
            <div>
              { item.data.map( (subitem, id) => (
                  <div key={id}>
                    <input type="checkbox" checked readOnly />
                    <label htmlFor="">{subitem}</label>
                  </div>
              ) ) }
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default KTPChecking