import { createElement, lazy } from "react";
import { FaHome, FaBox, FaHandHoldingHeart, FaPhoneAlt, FaUserTie, FaUserFriends, FaUserTag, FaPhone, FaServer  } from "react-icons/fa";

// lazy load
const Layout = lazy(() => import('../pages/layout'))
const Home = lazy(() => import('../pages/home'))
const Conversation = lazy(() => import('../pages/conversation'))
const Standby = lazy(() => import('../pages/standby'))
const Login = lazy(() => import('../pages/login'))

export const ROUTES = [
  {
    element: createElement(Layout),
    path: '/',
    children: [
      {
        index: true,
        element: createElement(Home),
        icon: createElement(FaHome),
      },
      {
        path: 'log',
        element: createElement(Conversation),
        icon: createElement(FaPhone),
      },
      { 
        path: 'conversation',
        element: createElement(Conversation),
        hide: true,
      },
      {
        path: 'produk',
        icon: createElement(FaBox), 
        accordion: true,
        children: [
          { element: createElement(Standby), path: 'product1', icon: createElement(FaBox) },
          { element: createElement(Standby), path: 'product2', icon: createElement(FaBox) },
          { element: createElement(Standby), path: 'product3', icon: createElement(FaBox) },
        ]
      },
      {
        path: 'layanan',
        icon: createElement(FaServer), 
        accordion: true,
        children: [
          { 
            element: createElement(Standby),
            path: 'open-account',
            index: true,
            icon: createElement(FaHandHoldingHeart)
          },
          { element: createElement(Standby), path: 'RTGS', icon: createElement(FaHandHoldingHeart) },
          { element: createElement(Standby), path: 'tax', icon: createElement(FaHandHoldingHeart) },
          { element: createElement(Standby), path: 'deposito', icon: createElement(FaHandHoldingHeart) },
          { element: createElement(Standby), path: 'customer-complain', icon: createElement(FaHandHoldingHeart) },
        ]
      },
      {
        path: 'panggil',
        icon: createElement(FaPhoneAlt), 
        accordion: true,
        children: [
          { path: 'supervisor', icon: createElement(FaUserTie), element: null },
          { path: 'marketing-kredit', icon: createElement(FaUserFriends), element: null },
          { path: 'wealth', icon: createElement(FaUserTag), element: null },
        ]
      },
    ]
  }
];

