import { Card, Col, Stack } from "react-bootstrap";
import Row from "react-bootstrap/Row";

function PortofolioNasabah() {
  return (
    <div style={{ display: "block" }}>
      <Row>
        <Col md={4}>
          <div className="mb-2 text-center" style={{ color: "#FF3B3B" }}>
            Dana Pihak Ketiga
          </div>
          <Card
            className="fw-normal p-2 mb-2"
            style={{ borderRadius: "5px", border: "1px solid black" }}
          >
            <Stack direction="horizontal" gap={2}>
              <div className="me-3 col-md-2">
                <img
                  style={{
                    width: "30px",
                    height: "30px", //,
                    // position: "absolute",
                    // right: "9px",
                    // top: "6px",
                  }}
                  src={require("./portofolioicon/giro.png")}
                ></img>
              </div>
              <div>
                <Row className="fw-bold" style={{ fontSize: "11px" }}>
                  Giro DiPS Rp - 022347001
                </Row>
                <Row className="" style={{ fontSize: "11px" }}>
                  Rp 15.000.000,00
                </Row>
              </div>
            </Stack>
          </Card>
          <Card
            className="fw-normal p-2 mb-2"
            style={{ borderRadius: "5px", border: "1px solid black" }}
          >
            <Stack direction="horizontal" gap={2}>
              <div className="me-3 col-md-2">
                <img
                  style={{
                    width: "30px",
                    height: "30px", //,
                    // position: "absolute",
                    // right: "9px",
                    // top: "6px",
                  }}
                  src={require("./portofolioicon/tabungan.png")}
                ></img>
              </div>
              <div>
                <Row className="fw-bold" style={{ fontSize: "11px" }}>
                  Tabungan DiPS Rp - 022347001
                </Row>
                <Row className="" style={{ fontSize: "11px" }}>
                  Rp 12.000.000,00
                </Row>
              </div>
            </Stack>
          </Card>
          <Card
            className="fw-normal p-2 mb-2"
            style={{ borderRadius: "5px", border: "1px solid black" }}
          >
            <Stack direction="horizontal" gap={2}>
              <div className="me-3 col-md-2">
                <img
                  style={{
                    width: "30px",
                    height: "30px", //,
                    // position: "absolute",
                    // right: "9px",
                    // top: "6px",
                  }}
                  src={require("./portofolioicon/deposito.png")}
                ></img>
              </div>
              <div>
                <Row className="fw-bold" style={{ fontSize: "11px" }}>
                  Deposito DiPS Rp - 054999201
                </Row>
                <Row className="" style={{ fontSize: "11px" }}>
                  Rp 100.000.000,00
                </Row>
              </div>
            </Stack>
          </Card>
        </Col>
        <Col md={4}>
          <div className="mb-2 text-center" style={{ color: "#FF3B3B" }}>
            Investasi
          </div>
          <Card
            className="fw-normal p-2 mb-2"
            style={{ borderRadius: "5px", border: "1px solid black" }}
          >
            <Stack direction="horizontal" gap={2}>
              <div className="me-3 col-md-2">
                <img
                  style={{
                    width: "30px",
                    height: "30px", //,
                    // position: "absolute",
                    // right: "9px",
                    // top: "6px",
                  }}
                  src={require("./portofolioicon/wealthlink.png")}
                ></img>
              </div>
              <div>
                <Row className="fw-bold" style={{ fontSize: "11px" }}>
                  DiPS Wealthlink
                </Row>
                <Row className="" style={{ fontSize: "11px" }}>
                  Rp 1.000.000.000,00
                </Row>
              </div>
            </Stack>
          </Card>
          <Card
            className="fw-normal p-2 mb-2"
            style={{ borderRadius: "5px", border: "1px solid black" }}
          >
            <Stack direction="horizontal" gap={2}>
              <div className="me-3 col-md-2">
                <img
                  style={{
                    width: "30px",
                    height: "30px", //,
                    // position: "absolute",
                    // right: "9px",
                    // top: "6px",
                  }}
                  src={require("./portofolioicon/protect life.png")}
                ></img>
              </div>
              <div>
                <Row className="fw-bold" style={{ fontSize: "11px" }}>
                  DiPS Protect Life
                </Row>
                <Row className="" style={{ fontSize: "11px" }}>
                  Rp 500.000.000,00
                </Row>
              </div>
            </Stack>
          </Card>
          <Card
            className="fw-normal p-2 mb-2"
            style={{ borderRadius: "5px", border: "1px solid black" }}
          >
            <Stack direction="horizontal" gap={2}>
              <div className="me-3 col-md-2">
                <img
                  style={{
                    width: "30px",
                    height: "30px", //,
                    // position: "absolute",
                    // right: "9px",
                    // top: "6px",
                  }}
                  src={require("./portofolioicon/money market fund.png")}
                ></img>
              </div>
              <div>
                <Row className="fw-bold" style={{ fontSize: "11px" }}>
                  DiPS Money Market Fund
                </Row>
                <Row className="" style={{ fontSize: "11px" }}>
                  Rp 18.000.000,00
                </Row>
              </div>
            </Stack>
          </Card>
          <Card
            className="fw-normal p-2 mb-2"
            style={{ borderRadius: "5px", border: "1px solid black" }}
          >
            <Stack direction="horizontal" gap={2}>
              <div className="me-3 col-md-2">
                <img
                  style={{
                    width: "30px",
                    height: "30px", //,
                    // position: "absolute",
                    // right: "9px",
                    // top: "6px",
                  }}
                  src={require("./portofolioicon/ori 022.png")}
                ></img>
              </div>
              <div>
                <Row className="fw-bold" style={{ fontSize: "11px" }}>
                  ORI 022
                </Row>
                <Row className="" style={{ fontSize: "11px" }}>
                  Rp 50.000.000.000,00
                </Row>
              </div>
            </Stack>
          </Card>
          <Card
            className="fw-normal p-2 mb-2"
            style={{ borderRadius: "5px", border: "1px solid black" }}
          >
            <Stack direction="horizontal" gap={2}>
              <div className="me-3 col-md-2">
                <img
                  style={{
                    width: "30px",
                    height: "30px", //,
                    // position: "absolute",
                    // right: "9px",
                    // top: "6px",
                  }}
                  src={require("./portofolioicon/ori 022.png")}
                ></img>
              </div>
              <div>
                <Row className="fw-bold" style={{ fontSize: "11px" }}>
                  SR 014
                </Row>
                <Row className="" style={{ fontSize: "11px" }}>
                  Rp 1.000.000.000,00
                </Row>
              </div>
            </Stack>
          </Card>
        </Col>
        <Col md={4}>
          <div className="mb-2 text-center" style={{ color: "#FF3B3B" }}>
            Kredit
          </div>
          <Card
            className="fw-normal p-2 mb-2"
            style={{ borderRadius: "5px", border: "1px solid black" }}
          >
            <Stack direction="horizontal" gap={2}>
              <div className="me-3 col-md-2">
                <img
                  style={{
                    width: "30px",
                    height: "30px", //,
                    // position: "absolute",
                    // right: "9px",
                    // top: "6px",
                  }}
                  src={require("./portofolioicon/kredit.png")}
                ></img>
              </div>
              <div>
                <Row className="fw-bold" style={{ fontSize: "11px" }}>
                  DiPS Kredit Modal Kerja - 992101345
                </Row>
                <Row className="" style={{ fontSize: "11px" }}>
                  Rp 10.500.000.000,00
                </Row>
              </div>
            </Stack>
          </Card>
          <Card
            className="fw-normal p-2 mb-2"
            style={{ borderRadius: "5px", border: "1px solid black" }}
          >
            <Stack direction="horizontal" gap={2}>
              <div className="me-3 col-md-2">
                <img
                  style={{
                    width: "30px",
                    height: "30px", //,
                    // position: "absolute",
                    // right: "9px",
                    // top: "6px",
                  }}
                  src={require("./portofolioicon/kredit.png")}
                ></img>
              </div>
              <div>
                <Row className="fw-bold" style={{ fontSize: "11px" }}>
                  DiPS Kredit Investasi - 992201209
                </Row>
                <Row className="" style={{ fontSize: "11px" }}>
                  Rp 500.000.000,00
                </Row>
              </div>
            </Stack>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PortofolioNasabah;
