import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { proceed } from "app/features/inbound";
import { AppDispatch } from "app/store";
import { _HOST } from "config/constant/endpoints";
import useSocket from "utilities/hooks/use-socket";
import styles from "./index.module.scss";
import { endAndHome } from "components/molecules/navbar";
import axios from "axios";
import { nasabahInterface } from "../../portfolio";

const AgreeToUseService = () => {
  const dispatch: AppDispatch = useDispatch();
  const [nasabah, setNasabah] = useState<nasabahInterface>();

  const { onConnect } = useSocket();
  const session = localStorage.getItem("pickup")!;

  return (
    <div className={styles.confirmation}>
      <h2>Apakah Anda setuju menggunakan layanan DiPS 361</h2>
      <div>
        <button>Tidak</button>
        <button>Setuju</button>
      </div>
    </div>
  );
};

export default AgreeToUseService;
