import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch, SetStateAction } from "react";
import { RootState } from "app/store";

export interface TimeState {
  seconds: number
  minutes: number
  hours: number
}

export interface TimerState {
  isStart: boolean
  all: number
  total: () => number
  duration: TimeState
  counter: number
}

const defaultTimer: TimeState = {
  seconds: 0,
  minutes: 0,
  hours: 0
}

export const initialState: TimerState = {
  isStart: false,
  all: 0,
  total: function() {
    let time = this.all
    return time !== this.all ? time + this.all : this.all
  },
  duration: defaultTimer,
  counter: 0
}

export type SetTimerState = Dispatch<SetStateAction<TimerState>>

export const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    stopRecord: (state) => {
      return {
        ...state,
        counter: 0
      }
    },
    onRecord: (state) => {
      return {
        ...state,
        all: state.all + 1,
        counter: state.counter + 1
      }
    }
  }
})

export const { stopRecord, onRecord } = timerSlice.actions

export const selectTimer = (state: RootState) => state.timer
export default timerSlice.reducer