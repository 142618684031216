import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
// import store from './config/redux/store';
import ZoomContext from "context/zoom-context";
import ZoomVideo from "@zoom/videosdk";
import Loader from "components/atoms/loader";
import Loader2 from "components/atoms/loader/withoutSoket";
import store from "app/store";
import "bootstrap/dist/css/bootstrap.min.css";
// import ChatContext from 'context/chat-context';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const zoomClient = ZoomVideo.createClient();

root.render(
  <React.StrictMode>
    {/* <AuthProvider> */}
    <React.Suspense fallback={<Loader2 />}>
      <Provider store={store}>
        <ZoomContext.Provider value={zoomClient}>
          {/* <ChatContext.Provider value={ChatClient} > */}
          <Router>
            <App />
          </Router>
          {/* </ChatContext.Provider> */}
        </ZoomContext.Provider>
      </Provider>
    </React.Suspense>
    {/* </AuthProvider> */}
  </React.StrictMode>
);

// function RequireAuth({ children, redirectTo }) {
//   let isAuthenticated = getAuth();
//   return isAuthenticated ? children : <Redirect to={redirectTo}>;
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
