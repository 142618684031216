import { createElement, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { FaPlusCircle } from "react-icons/fa";
import styles from "./index.module.scss";
import ttd from "assets/images/ttd.png";
import StatusUpload from "components/molecules/status-bar/upload";
import useSocket from "utilities/hooks/use-socket";
import { useDispatch } from "react-redux";
import { proceed } from "app/features/inbound";
import { DOCS_OPEN_ACCOUNT } from "config/initial-state";
import { AppDispatch } from "app/store";
import { _HOST } from "config/constant/endpoints";
import { endAndHome } from "components/molecules/navbar";

const TTD = () => {
  const [stringImg, setImage] = useState<string>("");
  const dispatch: AppDispatch = useDispatch();
  const { onConnect } = useSocket();
  const session = localStorage.getItem("pickup")!.split(",");

  useEffect(() => {
    const socket = io(_HOST);
    socket.on("connect", onConnect);
    socket.emit("call", "join", { room: session[1] });
    socket.on("conferencing", (...arg: any) => {
      console.log(arg);
      const isProceed = arg[arg.length - 1];
      if (arg[0] === 7 && arg[1] !== "") {
        setImage(arg[1]);
        localStorage.setItem("ttd", arg[1]);
      }
      if (arg[1] === "" && arg[0] === 7) {
        setImage(arg[1]);
      }
    });

    return () => {
      socket.off("connect");
      socket.off("conferencing");
      socket.close();
    };
  }, []);

  return (
    <div className={styles.signature}>
      <StatusUpload status={DOCS_OPEN_ACCOUNT} />
      {stringImg !== "" ? (
        <>
          <div>
            <img src={`data:image/png;base64,${stringImg}`} alt="ttd" />
          </div>
        </>
      ) : (
        <div>
          <div>{createElement(FaPlusCircle)}</div>
        </div>
      )}
    </div>
  );
};

export default TTD;
