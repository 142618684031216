import styles from "./index.module.scss";

interface PropsConfirm {
  halaman: number;
}

function ConfirmationRTGSElement(props: PropsConfirm) {
  const data = JSON.parse(localStorage.getItem(`rtgs-form-${props.halaman}`)!);

  return (
    <>
      {data && (
        <div className={styles.container}>
          <div className={styles.form}>
            <div className={styles.upload}>
              <label htmlFor="">Dari: </label>
              <div>
                <p>
                  {data.length > 0 && data[2]
                    ? data[2]
                    : localStorage.getItem(`rtgs-form-1`)!.split(",")[2]}
                </p>
                <p>
                  {data.length > 0 && data[3]
                    ? data[3]
                    : localStorage.getItem(`rtgs-form-1`)!.split(",")[3]}
                </p>
                <p>
                  {data.length > 0 && data[4]
                    ? data[4]
                    : localStorage.getItem(`rtgs-form-1`)!.split(",")[4]}
                </p>
              </div>
            </div>
            <div className={styles.source}>
              <label htmlFor="">Penerima: </label>
              <div>
                <b>
                  {data.length > 0 && data[5]
                    ? data[5]
                    : localStorage.getItem(`rtgs-form-1`)!.split(",")[5]}
                </b>{" "}
                {`${
                  data.length > 0 && data[6]
                    ? data[6]
                    : localStorage.getItem(`rtgs-form-1`)!.split(",")[6]
                } - ${
                  data.length > 0 && data[7]
                    ? data[7]
                    : localStorage.getItem(`rtgs-form-1`)!.split(",")[7]
                }`}
              </div>
            </div>
          </div>
          <div className={styles.table}>
            <div>
              <p>Jenis Layanan</p>
              <p>Penerima Manfaat</p>
              <p>Jenis Penduduk</p>
              <p>Biaya</p>
              <p>Nominal Transaksi</p>
              <p>Berita</p>
            </div>
            <div>
              <p>
                {data.length > 0 && data[9]
                  ? data[9]
                  : localStorage.getItem(`rtgs-form-1`)!.split(",")[9]}
              </p>
              <p>
                {data.length > 0 && data[10]
                  ? data[10]
                  : localStorage.getItem(`rtgs-form-1`)!.split(",")[10]}
              </p>
              <p>
                {data.length > 0 && data[11]
                  ? data[11]
                  : localStorage.getItem(`rtgs-form-1`)!.split(",")[11]}
              </p>
              <p>Rp 2,500</p>
              <p>
                {data.length > 0 && data[8]
                  ? data[8]
                  : localStorage.getItem(`rtgs-form-1`)!.split(",")[8]}
              </p>
              <p>
                {data.length > 0 && data[12]
                  ? localStorage.getItem(`rtgs-form-1`)!.split(",")[12] !== " "
                    ? data[12]
                    : " - "
                  : "-"}
              </p>
            </div>
          </div>
          <h4>
            halaman {data && data[13] ? data[13] : 1} dari{" "}
            {data && data[14]
              ? data[14] === 5
                ? localStorage.getItem(`rtgs-form-1`)!.split(",")[14]
                : data[14]
                ? data[14]
                : ""
              : 1}
          </h4>
        </div>
      )}
    </>
  );
}

export default ConfirmationRTGSElement;
