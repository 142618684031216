import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import styles from "./index.module.scss";
import useSocket from "utilities/hooks/use-socket";
import { proceed } from "app/features/inbound";
import { _HOST } from "config/constant/endpoints";
import { AppDispatch } from "config/redux";
import { endAndHome } from "components/molecules/navbar";

const CheckingCustomer = () => {
  const nikRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const dispatch: AppDispatch = useDispatch();
  const { onConnect } = useSocket();
  const session = localStorage.getItem("pickup")!.split(",");

  useEffect(() => {
    const socket = io(_HOST);
    socket.on("connect", onConnect);
    socket.emit("call", "join", { room: session[1] });
    socket.on("conferencing", (...arg: any) => {
      console.log(arg);
      const isProceed = arg[arg.length - 1];
      if (nikRef && nameRef) {
        nameRef.current!.value = arg[1];
        nikRef.current!.value = arg[2];
      }
    });

    return () => {
      console.log("disconnect checking");

      socket.off("connect");
      socket.off("conferencing");
      socket.close();
    };
  }, []);

  return (
    <form className={styles.form}>
      <div>
        <label>
          <strong>Nama</strong>
        </label>
        <input
          id="name"
          type="text"
          placeholder="Masukan Nama Anda"
          disabled
          ref={nameRef}
        />
      </div>
      <div>
        <label>
          <strong>NIK</strong>
        </label>
        <input
          id="nik"
          type="number"
          placeholder="Masukan NIK Anda"
          disabled
          ref={nikRef}
        />
      </div>
    </form>
  );
};

export default CheckingCustomer;
