import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createElement, FunctionComponentElement } from "react";
import AgreeToUseService from "pages/conversation/features/cif-opening/agree-to-service";
import CheckingCustomer from "pages/conversation/features/cif-opening/checking-customer";
import FormOpeningAccount from "pages/conversation/features/cif-opening/form-opening";
import KTP from "pages/conversation/features/cif-opening/ktp";
import NPWP from "pages/conversation/features/cif-opening/npwp";
import OTP from "pages/conversation/features/cif-opening/otp";
import ServiceInbound from "pages/conversation/features/cif-opening/service";
import Resi from "pages/conversation/features/cif-opening/succes-activation";
import SuccesRegister from "pages/conversation/features/cif-opening/sucess-register";
import UserPortfolio from "pages/conversation/features/portfolio";
import { RootState } from "app/store";
import Services from "pages/conversation/features/services";
import FormRTGS from "pages/conversation/features/rtgs/form";
import RTGSConfirmation from "pages/conversation/features/rtgs/confirmation";
import RTGSResi from "pages/conversation/features/rtgs/resi";
import TTD from "pages/conversation/features/cif-opening/ttd";
import IBMB from "pages/conversation/features/cif-opening/ibmb";

interface LabelState {
  id: string;
  en: string;
}

export type InboundState = {
  id: number;
  label?: LabelState;
  onProcess: boolean;
  element: FunctionComponentElement<{}>;
};

const initialState: InboundState[] = [
  { id: 0, onProcess: true, element: createElement(AgreeToUseService) },
  { id: 1, onProcess: false, element: createElement(CheckingCustomer) },
  {
    id: 2,
    label: { id: "Produk dan Layanan", en: "Products & Services" },
    onProcess: false,
    element: createElement(ServiceInbound),
  },
  {
    id: 3,
    label: { id: "KTP", en: "Citizen ID Card" },
    onProcess: false,
    element: createElement(KTP),
  },
  {
    id: 4,
    label: { id: "NPWP", en: "Taxpayer ID Number" },
    onProcess: false,
    element: createElement(NPWP),
  },
  {
    id: 5,
    label: { id: "Tanda Tangan", en: "Taxpayer ID Number" },
    onProcess: false,
    element: createElement(TTD),
  },
  {
    id: 6,
    label: { id: "Formulir Pendaftaran", en: "Registration Form" },
    onProcess: false,
    element: createElement(FormOpeningAccount),
  },
  { id: 7, onProcess: false, element: createElement(SuccesRegister) },
  {
    id: 8,
    label: { id: "Aktivasi M-Banking", en: "M-Banking Activation" },
    onProcess: false,
    element: createElement(IBMB),
  },
  { id: 9, onProcess: false, element: createElement(OTP) },
  { id: 10, onProcess: false, element: createElement(Resi) },
  { id: 11, onProcess: false, element: createElement(UserPortfolio) },
  { id: 12, onProcess: false, element: createElement(Services) },
  { id: 13, onProcess: false, element: createElement(FormRTGS) },
  { id: 14, onProcess: false, element: createElement(RTGSConfirmation) },
  { id: 15, onProcess: false, element: createElement(RTGSResi) },
];

export const inboundSlice = createSlice({
  name: "inbound",
  initialState,
  reducers: {
    proceed: (state, action: PayloadAction<number>) => {
      // const onProcess = state.findIndex((item: InboundState) => item.onProcess)
      console.log(action.payload, "payload", state);
      return state.map((item: InboundState, id: number) => {
        // console.log(item, id, action.payload, "item", id !== action.payload);
        if (id !== action.payload) {
          return {
            ...item,
            onProcess: false,
          };
        } else {
          return {
            ...item,
            onProcess: true,
          };
        }
      });
    },
  },
});

export const { proceed } = inboundSlice.actions;
export const selectInbound = (state: RootState) => state.inbound;
export default inboundSlice.reducer;
