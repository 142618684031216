import { proceed } from "app/features/inbound";
import { AppDispatch } from "app/store";
import { endAndHome } from "components/molecules/navbar";
import Tabbar from "components/molecules/tab";
import { _HOST } from "config/constant/endpoints";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import useSocket from "utilities/hooks/use-socket";
import styles from "./index.module.scss";

const services = [
  { label: "Pembukaan Rekening", img: "https://bit.ly/3Mvp2mj" },
  { label: "Transaksi RTGS", img: "https://bit.ly/3997k9K" },
  { label: "Transaksi SKN", img: "https://bit.ly/3rV7OXx" },
  { label: "Transaksi Remittances", img: "https://bit.ly/3LqncTB" },
  {
    label: "Transaksi Antar Bank (Antar Account)",
    img: "https://bit.ly/3rXsoGP",
  },
  { label: "Transaksi Antar Bank (Deposit)", img: "https://bit.ly/3Mvp2mj" },
  { label: "Transaksi Antar Bank (MD)", img: "https://bit.ly/3997k9K" },
  { label: "Aktivasi Dormant", img: "https://bit.ly/3rV7OXx" },
  { label: "Transaksi Valas", img: "https://bit.ly/3LqncTB" },
  { label: "Blokir Kartu ATM", img: "https://bit.ly/3rXsoGP" },
  { label: "Pembuatan Kartu ATM", img: "https://bit.ly/3Mvp2mj" },
  { label: "Aktivasi Kartu ATM", img: "https://bit.ly/3997k9K" },
  { label: "Transaksi Cardless Cash", img: "https://bit.ly/3rV7OXx" },
  { label: "P O A", img: "https://bit.ly/3LqncTB" },
  { label: "Aktivasi Dormant", img: "https://bit.ly/3rV7OXx" },
  { label: "Transaksi Valas", img: "https://bit.ly/3LqncTB" },
  { label: "Blokir Kartu ATM", img: "https://bit.ly/3rXsoGP" },
  { label: "Pembuatan Kartu ATM", img: "https://bit.ly/3Mvp2mj" },
  { label: "Aktivasi Kartu ATM", img: "https://bit.ly/3997k9K" },
  { label: "Transaksi Cardless Cash", img: "https://bit.ly/3rV7OXx" },
  { label: "P O A", img: "https://bit.ly/3LqncTB" },
];

const initialTab = [
  { label: "Portofolio", isActive: false },
  { label: "Layanan", isActive: true },
];

const Services = () => {
  const dispatch: AppDispatch = useDispatch();
  const { onConnect } = useSocket();
  const session = localStorage.getItem("pickup")!.split(",");

  const left = services.map((item, id) => {
    if (id < services.length / 2) {
      return (
        <button key={id}>
          {item.label} <img src={item.img} alt="product image" />
        </button>
      );
    }
  });

  const right = services.map((item, id) => {
    if (id > (services.length - 1) / 2) {
      return (
        <button key={id}>
          {item.label} <img src={item.img} alt="product image" />
        </button>
      );
    }
  });

  return (
    <div className={styles.services}>
      <Tabbar tabs={initialTab} />
      <h3>Daftar Layanan</h3>
      <div>
        <div>{left}</div>
        <div>{right}</div>
      </div>
    </div>
  );
};

export default Services;
