import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { CustomerQueue } from "../../../config/types";

export interface QueueStateList {
  list: CustomerQueue[];
}

export const initialState: QueueStateList = {
  list: [],
};

export const queueList = createSlice({
  name: "queue-list",
  initialState,
  reducers: {
    queueData: (state, action: PayloadAction<QueueStateList>) => {
      return {
        ...state,
        list: action.payload.list,
      };
    },
  },
});

export const { queueData } = queueList.actions;
export const selectQueueList = (state: RootState) => state.queueList.list;
export default queueList.reducer;
