import { endAndHome } from "components/molecules/navbar";
import { _HOST } from "config/constant/endpoints";
import { useEffect } from "react";
import { io } from "socket.io-client";
import styles from "./index.module.scss";

const Loader = () => {
  const idDips = localStorage.getItem("pickup")!.split(",")[1];
  useEffect(() => {
    const socket = io(_HOST);
    socket.on("connect", () => {
      console.log("connected conferencing >>>> loader");
    });
    socket.emit("call", "join", { room: idDips });
    socket.on("conferencing", (...arg) => {
      if (arg[0] === 99 && arg[1]) {
        endAndHome(JSON.parse(localStorage.getItem("zoom-client")!));
      }
    });
    return () => {
      console.log("destroy conferencing >>>> loader");

      socket.off("conferencing");
      socket.off("connect");
      socket.close();
    };
  }, [idDips]);
  console.log("loader without soket");

  return <div className={styles.loader}></div>;
};

export default Loader;
