import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChatRecord } from "config/types";
import { RootState } from "app/store";

export interface ChatState {
  isShow: boolean
  records: ChatRecord[]
}

const initialState: ChatState = {
  isShow: false,
  records: []
}

export const chatSlice = createSlice({
  name: 'chat-on-meeting',
  initialState,
  reducers: {
    toggleShowChat: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isShow: action.payload,
      }
    },
    logChat: (state, action: PayloadAction<ChatRecord[]>) => {
      return {
        ...state,
        records: action.payload
      }
    }
  }
})

export const { toggleShowChat, logChat } = chatSlice.actions
export const selectChat = (state: RootState) => state.chatOnMeeting
export default chatSlice.reducer