import { FaBook, FaFileSignature, FaIdCard, FaIdCardAlt } from "react-icons/fa"
import KTP from "../../pages/conversation/features/cif-opening/ktp"
import NPWP from "../../pages/conversation/features/cif-opening/npwp"
import TTD from "../../pages/conversation/features/cif-opening/ttd"
import FormOpeningAccount from "../../pages/conversation/features/cif-opening/form-opening"
import { createElement } from "react"

export const TIMER_STATE = {
  initTimer: false,
  seconds: 0,
  minutes: 0,
  hours: 0,
}

export const DOCS_OPEN_ACCOUNT = [
  { label: 'KTP', icon: createElement(FaIdCard) },
  { label: 'NPWP', icon: createElement(FaIdCardAlt) },
  { label: 'Tanda Tangan', icon: createElement(FaFileSignature) },
  { label: 'Formulir', icon: createElement(FaBook) },
]

export const scheduledCustomers = [
  { name: 'Budi Budiono', detail: '08:00 - 09:00 WIB', type: 'scheduled', isRegistered: true },
  { name: 'Putri Ratnasari', detail: '09:00 - 10:00 WIB', type: 'scheduled', isRegistered: true },
  { name: 'Muhammad Ahmad', detail: '10:00 - 11:00 WIB', type: 'scheduled', isRegistered: false },
  { name: 'Bambang Waluyo', detail: '10:00 - 11:00 WIB', type: 'scheduled', isRegistered: true },
  { name: 'Andi Pamungkas', detail: '11:00 - 12:00 WIB', type: 'scheduled', isRegistered: false },
  { name: 'Ayu Sari', detail: '12:00 - 13:00 WIB', type: 'scheduled', isRegistered: true },
]

export const queues = [
  { name: 'A003', detail: 'Incoming', type: 'queue', isRegistered: false },
  { name: 'A004', detail: 'In Queue', type: 'queue', isRegistered: false },
  { name: 'A005', detail: 'In Queue', type: 'queue', isRegistered: true },
  { name: 'A006', detail: 'In Queue', type: 'queue', isRegistered: false },
  { name: 'A007', detail: 'In Queue', type: 'queue', isRegistered: true },
]

export const globalState = {
  isLoggedIn: false,
  openAccount: DOCS_OPEN_ACCOUNT,
  timer: TIMER_STATE,
  chat: false,
  isRegistered: false,
  isOnline: false,
  inMeeting: {},
  signatures: {}
}

export let VideoParticipants = {
  userId: 0,
  avatar: '',
  displayName: '',
  isHost: false,
  bVideoOn: false,
  muted: false,
  audio: '',
  sharerOn: '',
  sharerPause: '',
}

export let ArrayParticipants = [VideoParticipants]
