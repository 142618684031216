import { queueData, selectQueueList } from "app/features/queue";
import { AppDispatch } from "app/store";
import axios from "axios";
import { _QUEUE_OPTION_API } from "config/constant/endpoints";
import produce from "immer";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VideoParams } from "../../../config/types";
import useMeeting from "../meeting";

type CIFStepType = Array<string | number | boolean>;
const setDate = (options: any) =>
  new Intl.DateTimeFormat("id-ID", options).format(new Date());
const optDay = { year: "numeric", month: "2-digit", day: "2-digit" };
const yToday = setDate(optDay).split("/").reverse().join("");
const useSocket = () => {
  const [videoParams, setVideoParams] = useState<VideoParams | null>(null);
  const [ringing, setRinging] = useState<string>("");
  const [CIFStep, setCIfStep] = useState<CIFStepType>([]);
  const [currentAgent, setCurrentAgent] = useState(() => {
    return JSON.parse(localStorage.getItem("agent")!);
  });
  const queueList = useSelector(selectQueueList);
  const dispatch: AppDispatch = useDispatch();
  const onConnect = useCallback(() => console.log("socket connected"), []);
  const offConnect = useCallback(() => console.log("socket off connect"), []);
  const onMirror = useCallback((...arg: CIFStepType) => {
    setCIfStep([...arg]);
  }, []);

  const onStandby = useCallback(
    (...arg: Array<string>) => {
      console.log(JSON.stringify(arg), arg[0]);
      if (arg[4] === currentAgent.data.username) {
        setVideoParams({
          role: arg[0],
          sessionName: arg[arg.length - 1],
          queueID: arg[2],
          sessionKey: arg[3],
          userId: arg[4],
        });
        localStorage.setItem("pickup", arg.toString());
        localStorage.setItem("pickup2", arg.toString());
        setRinging(arg[2]);
      } else {
        console.log("test onstanby");
        axios
          .get(_QUEUE_OPTION_API(yToday))
          .then((response) => {
            // console.log(response.data);
            dispatch(queueData({ list: response.data }));
            // console.log("queuelist", queueList);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    [currentAgent.data.username]
  );
  // console.log("usesocket");
  return {
    CIFStep,
    videoParams,
    ringing,
    onConnect,
    offConnect,
    onStandby,
    onMirror,
  };
};

export default useSocket;
