import { FunctionComponentElement } from 'react'
import styles from './index.module.scss'

type StatusProps = {
  label: string
  icon: FunctionComponentElement<{ }>
}

const StatusUpload = (props: { status: Array<StatusProps> }) => {
  
  return (
    <div className={styles.statusUpload}>
      {
        props.status && props.status.map(item => (
          <div key={item.label}>{item.icon} <p>{item.label}</p></div>
        ))
      }
    </div>
  )
}

export default StatusUpload