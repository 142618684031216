import axios from "axios";
import { _LOGIN_API, _TOGGLE_READY_API } from "../../../config/constant/endpoints";
import { Form } from "../../../config/types";

export const authLogin = async (form: Form) => {  
  return await axios.post( _LOGIN_API, {...form} ).then((res) => {
    if (res.data) {
      const data = {
        isLoggedIn: true,
        data: res.data
      }
      localStorage.setItem('agent', JSON.stringify(data))
      return res.data
    }
  })
}

export const authLogout = () => {
  localStorage.removeItem('agent')
  window.location.reload()
}

export const setOnline = async () => {  
  try {
    await axios.post(_TOGGLE_READY_API, {
      username: JSON.parse(localStorage.getItem('agent')!).data.username
    })
  } catch (err) {
    console.log(err);
  }
}