import { _HOST } from "config/constant/endpoints";
import { createElement, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { proceed } from "app/features/inbound";
import { AppDispatch } from "app/store";
import useSocket from "utilities/hooks/use-socket";
import styles from "./index.module.scss";
import { FaPlus } from "react-icons/fa";
import { endAndHome } from "components/molecules/navbar";
import ConfirmationRTGSElement from "./confirmation.element";

const ConfirmationRTGS = () => {
  const dispatch: AppDispatch = useDispatch();
  const { onConnect } = useSocket();
  const session = localStorage.getItem("pickup")!.split(",");
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("rtgs-form-1")!)
  );
  const [allPage, setAllPage] = useState(1);
  const [page, setPage] = useState(1);
  // const [transaksi, setTransaksi] = useState<Array>(localStorage.getItem("transaksi")!.split(","));
  // const userIDRef = useRef<HTMLInputElement>(null);
  // const passwordRef = useRef<HTMLInputElement>(null);
  // const confirmPassRef = useRef<HTMLInputElement>(null);
  // const MPINRef = useRef<HTMLInputElement>(null);
  // const confirmMPINRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const socket = io(_HOST);
    socket.on("connect", onConnect);
    socket.emit("call", "join", { room: session[1] });
    socket.on("conferencing", (...arg: any) => {
      console.log(arg);
      const isProceed = arg[arg.length - 1];
      // if (userIDRef) {
      //   userIDRef.current!.value = arg[1];
      //   passwordRef.current!.value = arg[2];
      //   confirmPassRef.current!.value = arg[3];
      //   MPINRef.current!.value = arg[4];
      //   confirmMPINRef.current!.value = arg[5];
      // }
      if (arg[2]) {
        // setData(JSON.parse(localStorage.getItem(`rtgs-form-${arg[2]}`)!));
        // setAllPage((curr) => arg[3]);
        setPage(Number(arg[2]));
      }
    });

    return () => {
      socket.off("connect");
      socket.off("conferencing");
      socket.close();
    };
  }, []);

  return (
    <>
      <ConfirmationRTGSElement halaman={page} />
    </>
  );
};

export default ConfirmationRTGS;
