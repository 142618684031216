import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import styles from "./index.module.scss";
import StatusUpload from "components/molecules/status-bar/upload";
import useSocket from "utilities/hooks/use-socket";
import ktp from "assets/images/ktp.jpg";
import npwp from "assets/images/npwp.jpg";
import ttd from "assets/images/ttd.png";
import { useDispatch } from "react-redux";
import { proceed } from "app/features/inbound";
import { DOCS_OPEN_ACCOUNT } from "config/initial-state";
import { AppDispatch } from "app/store";
import { _HOST } from "config/constant/endpoints";
import { endAndHome } from "components/molecules/navbar";
type netratype = {
  [key: string]: boolean;
};
const FormOpeningAccount = () => {
  const dispatch: AppDispatch = useDispatch();
  const { onConnect } = useSocket();
  const session = localStorage.getItem("pickup")!.split(",");

  const nameRef = useRef<HTMLInputElement>(null);
  const nikRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLTextAreaElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const religionRef = useRef<HTMLInputElement>(null);
  const maritalRef = useRef<HTMLInputElement>(null);
  const [tabungan, setTabungan] = useState({
    "Tabungan A": true,
    "Tabungan B": false,
  });
  const [statment, setStatment] = useState(false);
  // const accountRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const socket = io(_HOST);
    socket.on("connect", onConnect);
    socket.emit("call", "join", { room: session[1] });
    socket.on("conferencing", (...arg: any) => {
      console.log(arg);
      const isProceed = arg[arg.length - 1];
      nameRef.current!.value = arg[1];
      nikRef.current!.value = arg[2];
      emailRef.current!.value = arg[3];
      phoneRef.current!.value = arg[4];
      addressRef.current!.value = arg[5];
      religionRef.current!.value = arg[6];
      maritalRef.current!.value = arg[7];
      const keys = Object.keys(tabungan);
      let netral: netratype = tabungan;
      keys.forEach((key) => {
        netral[key] = false;
      });
      setTabungan({ ...tabungan, [arg[8]]: true });
      setStatment(arg[9]);
      if (arg[0] === 8 && arg[9]) {
        localStorage.setItem("form-cif", JSON.stringify(arg));
        // dispatch(proceed(7));
      }
    });

    return () => {
      socket.off("connect");
      socket.off("conferencing");
      socket.close();
    };
  }, []);

  return (
    <div className={styles.forms}>
      <StatusUpload status={DOCS_OPEN_ACCOUNT} />
      <div className={styles.form}>
        <div className={styles.single}>
          <label>Nama</label>
          <input
            type="text"
            placeholder="Masukan Nama Anda"
            ref={nameRef}
            disabled
          />
        </div>
        <div className={styles.sparated}>
          <div>
            <label>NIK</label>
            <input
              type="text"
              placeholder="Masukan NIK Anda"
              ref={nikRef}
              disabled
            />
          </div>
          <div>
            <label>Email</label>
            <input
              type="text"
              placeholder="Masukan Email Anda"
              ref={emailRef}
              disabled
            />
          </div>
        </div>
        <div className={styles.single}>
          <label>Alamat</label>
          <textarea
            name=""
            id=""
            rows={5}
            placeholder="Masukan Alamat Anda"
            ref={addressRef}
            disabled
          ></textarea>
        </div>
        <div className={styles.single}>
          <label>No Handphone</label>
          <input
            type="text"
            placeholder="Masukan No Handphone"
            ref={phoneRef}
            disabled
          />
        </div>
        <div className={styles.sparated}>
          <div>
            <label>Agama</label>
            <input
              type="text"
              placeholder="Masukan Agama Anda"
              ref={religionRef}
              disabled
            />
          </div>
          <div>
            <label>Status</label>
            <input
              type="text"
              placeholder="Masukan Status Anda"
              ref={maritalRef}
              disabled
            />
          </div>
        </div>
        <div className={styles.checker}>
          <label>Jenis Produk</label>
          <div>
            <div>
              <input
                type="checkbox"
                checked={tabungan["Tabungan A"]}
                readOnly
              />
              <label>Tabungan A</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={tabungan["Tabungan B"]}
                readOnly
              />
              <label>Tabungan B</label>
            </div>
          </div>
        </div>
        <div className={styles.upload}>
          <div>
            <img
              src={`data:image/png;base64,${localStorage.getItem("ktp")}`}
              alt="ktp customer"
            />
          </div>
          <div>
            <img
              src={`data:image/png;base64,${localStorage.getItem("npwp")}`}
              alt="npwp customer"
            />
          </div>
          <div>
            <img
              src={`data:image/png;base64,${localStorage.getItem("ttd")}`}
              alt="tanda tangan customer"
            />
          </div>
        </div>
        <div className={styles.tc}>
          <label>Syarat dan Ketentuan</label>
          <div>
            <label>
              <input type="checkbox" checked={statment} readOnly /> Saya
              menyatakan bahwa seluruh data yang saya berikan diformulir
              pembukaan rekening ini benar.
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormOpeningAccount;
